import './App.css';
import logo from './logo.png';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Miller Manufacturing and Sales online offerings
        </p>
        <a Check Back Soon />
        <p>MILLER MFG. & SALES INC.
        </p>

<a> Phone: 205.999.3569 </a>
<a> Email: DBMiller7@Outlook.com </a>
<a> P.O. Box: 101311 </a>
<a> Birmingham, AL 35210 </a>
        <p>
        </p>
      </header>
    </div>
  );
}

export default App;
